<template>
 <div style="margin-top: 100px">

   <van-row gutter="10">
     <van-col span="12">
       <van-button type="primary" @click="claimApply">协助理赔</van-button>
     </van-col>
     <van-col span="12">
       <van-button type="primary" @click="claimList">理赔查询或修改</van-button>
     </van-col>
   </van-row>

   <van-row style="padding-top: 50px">
     <van-col span="12">
       <van-button type="primary" @click="examQrCode">理赔授权</van-button>
     </van-col>
   </van-row>

   <!--   <van-row style="padding-top: 50px">-->

<!--     <van-col span="12">-->
<!--       <van-button type="primary" @click="insureView">投保</van-button>-->
<!--     </van-col>-->

<!--     <van-col span="12">-->
<!--       <van-button type="primary" @click="orderList">订单列表</van-button>-->
<!--     </van-col>-->

<!--   </van-row>-->

<!--   <van-field-->
<!--       clearable-->
<!--       v-model="inputStr"-->
<!--       label="身份证"-->
<!--       placeholder="被保人身份证号码"-->
<!--       :rules="[{ required: true, message: '请填写被保人身份证号码' }]"-->
<!--   />-->

<!--   <van-button type="primary" @click="inputDataStr">olo</van-button>-->

   <van-row style="padding-top: 50px">
     <van-col span="12">
       <van-button type="primary" @click="speed">加速</van-button>
     </van-col>
   </van-row>

 </div>
</template>

<script>

import {Dialog, Notify} from "vant";

export default {
  name:"IndexPage",

  data() {
    return {
      inputStr:""
    }
  },
  methods: {
    claimApply(){
      this.$router.push("/claim/policySelect")
    },
    claimList() {
      this.$router.push("/claim/claimSelect")
    },
    insureView() {
      this.$router.push("/insure/productList")
    },
    orderList() {
      this.$router.push("/order/select")
    },
    examQrCode() {
      this.$router.push("/claim/examSelect")
    },
    inputDataStr() {
    // var dateFormat =/^(\d{4})-(\d{2})-(\d{2})$/;
      var regex = /^((19|20)[0-9]{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
      if (regex.test(this.inputStr)) {
      Notify({ type: 'danger', message:  "是yyyy-MM-dd格式" });

    }else {
      Notify({ type: 'danger', message:  "=====不是yyyy-MM-dd格式" });
    }

    },
    speed(){
      Dialog.confirm({
        title: '提示',
        message: '授权成功，理赔进度您可通过“我的理赔”功能进行查询, 也可以通过“理赔加速”进行理赔加速！',
        cancelButtonText: '我的理赔',
        confirmButtonText: '理赔加速'
      }).then(() => {
        // on close
        //this.$router.push("/claim/policySelect");
        //windows.open("http://www.shandw.com/mi/game/2089120972.html?channel=21197")
        window.location.href= "http://www.shandw.com/mi/game/2089120972.html?channel=21197";
      }).catch(() => {
        // on cancel
        this.$router.push("/claim/policySelect");
      });
    }



  }
}
</script>

